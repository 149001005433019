import { createStore } from "vuex";

const store = createStore({
  state () {
    return {
      lang: '1'
    }
  },
})

export default store
// export default createStore({
//   state: {},
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });
